import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, Grid, Slide } from '@mui/material';
import GradientColorHeader from '@Local/Components/Dialog/GradientColorHeader';
import { TextField, Divider, Typography, Box, Switch, Stack, FormControlLabel, Button } from '@Components/';
import { UploadImageBox } from '@Local/Components/';
import usePointsRedeemItemApi from '@Apis/usePointsRedeemItemApi';
import { isEmpty, refIsRequiredError } from '@Util/utils';

const initData = {
    itemName: '',
    sku: '',
    points: 0,
    originalPrice: 0,
    costPrice: 0,
    imageSrc: '',
    imageDesc: [],
    isEnabled: false,
    itemID: null,
};

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsProductGiftItemDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {}, refresh } = props;
    if (!openProps) return null;
    const {
        getPointsProductGiftItemDataApi,
        postPointsProductGiftItemDataApi,
        putPointsProductGiftItemDataApi,
        postPointsProductGiftItemImageApi,
        postPointsProductGiftItemImageDescriptionApi,
    } = usePointsRedeemItemApi();
    const [sourceData, setSourceData] = useState({ ...initData });
    const itemNameRef = useRef(null);
    const skuRef = useRef(null);
    const pointsRef = useRef(null);
    const originalPriceRef = useRef(null);
    const costPriceRef = useRef(null);
    const isEnabledRef = useRef(null);
    const imageSrcRef = useRef(null);
    const imageDescRef = useRef(null);
    const {
        itemName = '',
        sku = '',
        points = 0,
        originalPrice = 0,
        costPrice = 0,
        itemID = null,
        imageSrc = '',
        imageDesc = [],
        isEnabled = false,
    } = sourceData || {};

    const getParams = () => {
        let params = {
            itemName: itemNameRef.current.getResult(),
            sku: skuRef.current.getResult(),
            points: pointsRef.current.getResult(),
            originalPrice: originalPriceRef.current.getResult(),
            costPrice: costPriceRef.current.getResult(),
            isEnabled: isEnabledRef.current.checked,
        };
        if (!isEmpty(itemID)) {
            params.itemID = itemID;
        }
        return params;
    };

    const isEditImageSrc = () => {
        const { file = '' } = imageSrcRef.current.getResult() || {};
        return imageSrc !== file;
    };
    const isEditImageDesc = () => {
        const { file = '' } = imageDescRef.current.getResult() || {};
        return imageDesc[0] !== file;
    };

    const handleConfirmOnClick = () => {
        if (!refIsRequiredError(itemNameRef, skuRef, pointsRef, originalPriceRef, costPriceRef)) {
            const params = getParams();
            if (!isEmpty(itemID)) {
                doEditItemApi(params, itemID);
            } else {
                doAddItemApi(params);
            }
        }
    };

    const doEditItemApi = async (params, targetID) => {
        const resp = await putPointsProductGiftItemDataApi(params, targetID);
        if (resp) {
            isEditImageSrc() && doImageSrcApi(imageSrcRef.current.getResult()?.file ?? '', targetID);
            isEditImageDesc() && doImageDescApi(imageDescRef.current.getResult()?.file ?? '', targetID);
            refresh();
            onCloseProps();
        }
    };

    const doAddItemApi = async (params) => {
        let targetID = 0;
        const resp = await postPointsProductGiftItemDataApi(params);
        if (resp) {
            targetID = resp;
            isEditImageSrc() && (await doImageSrcApi(imageSrcRef.current.getResult().file, targetID));
            isEditImageDesc() && (await doImageDescApi(imageDescRef.current.getResult().file, targetID));
            refresh();
            onCloseProps();
        }
    };

    const doImageSrcApi = async (params, targetID) => {
        const formData = new FormData();
        formData.append('file', params);
        await postPointsProductGiftItemImageApi(formData, targetID);
    };

    const doImageDescApi = async (params, targetID) => {
        const formData = new FormData();
        formData.append('file', params);
        await postPointsProductGiftItemImageDescriptionApi(formData, targetID);
    };

    const getItemDataApi = async (redeemItemID) => {
        const resp = await getPointsProductGiftItemDataApi(redeemItemID);
        resp && setSourceData(resp);
    };

    useEffect(
        () => {
            if (openProps && !isEmpty(sourceDataProps)) {
                const { itemID: targetID } = sourceDataProps;
                getItemDataApi(targetID);
            }
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>新增贈品</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        <Box key={itemID} className="pt-4">
                            <Stack direction="column" spacing={2}>
                                <TextField ref={itemNameRef} label="品名" defaultValue={itemName} fullWidth required />
                                <TextField ref={skuRef} label="SKU" defaultValue={sku} fullWidth required />
                            </Stack>
                            <Divider className="my-5" />
                            <Grid container spacing={2}>
                                <Grid xs={4} item>
                                    <TextField ref={pointsRef} label="兌換點數" maskType="MONEY" defaultValue={points} fullWidth required />
                                </Grid>
                                <Grid xs={4} item>
                                    <TextField ref={originalPriceRef} label="成本價" maskType="MONEY" defaultValue={originalPrice} fullWidth required />
                                </Grid>
                                <Grid xs={4} item>
                                    <TextField ref={costPriceRef} label="市價" maskType="MONEY" defaultValue={costPrice} fullWidth required />
                                </Grid>
                                <Grid xs={12} item>
                                    <FormControlLabel
                                        control={<Switch inputProps={{ ref: isEnabledRef }} defaultChecked={isEnabled} />}
                                        label="上架"
                                        labelPlacement="start"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid xs={6} item>
                        <Stack direction="column" spacing={2}>
                            <Box>
                                <Typography>贈品首圖</Typography>
                                <UploadImageBox
                                    ref={imageSrcRef}
                                    defaultValue={imageSrc}
                                    className="product-gift-item-file-img"
                                    accept={`image/gif, image/jpeg, image/jpg, image/png`}
                                    fileExtension={['gif', 'jpg', 'jpeg', 'png']}
                                    note="圖片大小為300x300"
                                />
                            </Box>
                            <Box>
                                <Typography>贈品描述圖</Typography>
                                <UploadImageBox
                                    ref={imageDescRef}
                                    defaultValue={imageDesc[0]}
                                    accept={`image/gif, image/jpeg, image/jpg, image/png`}
                                    fileExtension={['gif', 'jpg', 'jpeg', 'png']}
                                    note="圖片寬度為800長度不限"
                                    heightAuto
                                />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={12} item>
                        <Button variant="contained" onClick={handleConfirmOnClick} fullWidth>
                            送出
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
});

export default PointsProductGiftItemDialog;
